/* power reviews section */

#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: '是 ';
                visibility: visible;
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: '否 ';
                visibility: visible;
              }
            }
          }
          .pr-rd-bottomline {
            span {
              font-family: $base-font-family;
            }
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            font-family: $base-font-family;
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            span {
              font-family: $base-font-family;
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &::before {
            content: '篩選:';
          }
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            &::before {
              content: '排序方式: ';
            }
          }
        }
      }
    }
    .pr-review-snapshot-simple {
      .pr-review-snapshot-block {
        &.pr-review-snapshot-block-recommend {
          width: 23%;
        }
      }
    }
  }
}

.write_a_review__container {
  .pr-cb-tag-container {
    .pr-label-control {
      span {
        white-space: pre-wrap;
      }
    }
  }
}
