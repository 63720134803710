.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

fieldset {
  border: 0;
}

input[type='password'] {
  height: 36px;
  line-height: 15px;
  margin-top: 0;
  padding-left: 7px;
  padding-top: 0;
  width: 235px;
}

.margin_top_bottom {
  margin: 0.8em 0;
}

.padding_top_left {
  padding: 10px 0px 5px 10px;
}

.padding_right_left {
  padding: 5px 10px 5px 10px;
}

.demographic {
  select {
    height: 36px;
    line-height: 36px;
  }
}

.single-video-v1 {
  .image-placeholder {
    img {
      left: 80px;
    }
  }
}

.form-submit__button {
  margin: 0.8em 0;
}
