$border-color-grey: #ccc;

#gnav_signin {
  .signin_container {
    .register {
      .login-email {
        #register-form {
          .row {
            display: inline-block;
            width: 40%;
            .register-email-offers,
            .register-terms {
              width: 75%;
            }
          }
        }
      }
    }
  }
}

body#landing {
  .prod-shelf-row {
    li.product {
      position: relative;
      a.button {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

.section-store-locator {
  #store-search-controls {
    .door_container,
    .search_container {
      margin-left: 30px;
    }
  }
  .locations_map_panel {
    .gmnoprint {
      div[title^='向左平移'],
      div[title^='向下平移'],
      div[title^='向右平移'],
      div[title^='向上平移'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
    .gm-style-iw,
    .gm-style-iw > div {
      min-width: 250px;
    }
    .gm-style-iw {
      position: absolute !important;
    }
  }
}

.lpPoweredBy {
  display: none;
}

.content {
  .header-nav-formatter-v1 {
    .shortcuts {
      .header-nav-row-v1 {
        .lpPoweredBy {
          display: none;
        }
        .lpEmtStarRating {
          display: none;
        }
        .lpStaticButtonTR {
          a {
            padding: 0 0;
          }
        }
      }
      li.header-nav-row-v1.live-chat a {
        border: none !important;
        background: none !important;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
      }
      li.header-nav-row-v1.live-chat a img {
        border: none !important;
        background: none !important;
        margin-left: 5px;
        padding: 0;
        width: auto;
        height: 17px;
      }
    }
  }
}

.best-sellers {
  .products {
    img.product-image {
      min-height: 40px;
    }
    .product-info {
      min-height: 51px;
    }
  }
}

#customer_service {
  .wrapper {
    #main.contact_us_thanks {
      h2 {
        margin-top: 30px;
      }
    }
  }
}

#review {
  .pg_wrapper {
    #checkout_complete {
      .transaction_review {
        div.trans_detail_item {
          h3 {
            text-transform: uppercase;
            font-size: 14px;
            color: #222222;
          }
        }
      }
      .payment-panel {
        .header-section {
          clear: both;
          display: inline-block;
          width: 100%;
        }
        .non-installment-options-header,
        .installment-options-header {
          float: left;
          width: 75%;
        }
        .nccc-media {
          float: right;
          padding: 5px 5px 0 0;
        }
        .non-installment-options,
        .linepay-options,
        .installment-options {
          border: 1px solid $border-color-grey;
          margin-bottom: 10px;
        }
        .non-installment-options-header,
        .installment-options-header,
        .linepay-options-header {
          padding: 10px;
        }
        .non-installment-options-radios,
        .installment-options-radios,
        .linepay-options-radios {
          padding-left: 10px;
          margin: 0 0 0.8em 0;
        }
        .nccc-media img {
          height: 25px;
          width: 35px;
        }
        .linepay-options-radios img {
          width: 74px;
          height: 24px;
        }
      }
    }
  }
}

#favorites {
  .account-favorites-mylists {
    .create-wishlist-form {
      label {
        padding-top: 13px;
      }
    }
  }
  .favorite-product {
    .my-account-sku {
      .product-info {
        .product-name {
          a {
            display: block;
          }
        }
      }
    }
  }
}

#signin {
  #signin-block {
    input[type='checkbox'] {
      float: left;
      margin-top: 4px;
    }
  }
}

form#registration {
  .profile-form-container {
    .national_id_container {
      .margin_top_bottom {
        border: 1px solid #bdbdbd;
        margin-top: 0;
        margin-bottom: 0;
        height: 35px;
        p {
          padding-left: 13px;
        }
      }
    }
  }
  .sub_form {
    .demographic-form-container {
      .birth_date_container {
        select {
          padding: 0 0 0 14px;
        }
      }
      .gender_container {
        select {
          padding: 0 0 0 14px;
        }
      }
    }
  }
}

.account-header-panel {
  .chat {
    .lpEmtStarRating {
      display: none;
    }
    .lpStaticButtonTR {
      a {
        padding-bottom: 3px;
        opacity: 1;
      }
    }
  }
}

.page-spp {
  .price {
    .productsize {
      margin-left: 8px;
    }
  }
  .order_arrival {
    #order_arrival_info {
      display: none;
    }
  }
  .module-may-we-suggest {
    ul.product-grid.view-small {
      li.box {
        .product {
          h3 {
            margin: 3px 0 0 0 !important;
            line-height: 13px !important;
          }
        }
      }
    }
  }
}

.wrapper {
  .main-menu {
    .menu-item-block {
      .outer-wrap {
        .mm_3col_1tout_v1 {
          .col {
            h2 {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.linechat-overlay-container {
  position: fixed;
  background-color: white;
  border: 1px solid rgb(93, 186, 152);
  width: 500px;
  margin: 0 auto;
  padding: 20px;
  z-index: 10000;
  .close-container {
    float: right;
    a.close-link {
      font-size: 25px;
    }
  }
  .line_popup_content {
    .linechat_header {
      font-size: 24px;
      color: #44a07e;
    }
    .qr-image img {
      margin-left: 0px;
      width: 180px;
    }
    float: left;
    width: 80%;
  }
}

#shipping {
  #wrapper {
    #storeApi {
      .store-submit {
        background-color: #5dba98;
        text-transform: uppercase;
        color: white;
        line-height: 1.5;
        font-size: 0.9em;
        text-align: center;
        letter-spacing: 0;
        border: 0;
        padding: 4px 10px 3px;
        display: inline-block;
        zoom: 1;
      }
    }
  }
}

.section-videos {
  .wrap {
    .single-video-v1 {
      .video-wrapper {
        background-position: 70% 50% !important;
      }
    }
  }
}

#content {
  .cl-mpp-product-layout-v1 {
    .mpp-filter {
      &.collapsed {
        overflow: hidden;
        height: 80px;
      }
    }
    .mpp-grid ul.view-small {
      li.mpp-box {
        width: 186px;
        .badge {
          left: 0px;
        }
      }
    }
    .mpp-grid li.mpp-box {
      min-height: 400px;
      .product-info {
        height: 200px;
        .mpp-button-holder {
          position: absolute;
          bottom: 10px;
          left: 0px;
          right: 0px;
          a.button {
            padding: 5px 10px 3px;
          }
          .btn-shopnow {
            visibility: visible;
            margin-left: 0px;
            float: right;
            padding: 3px 10px !important;
          }
          .sold_out_stock_msg {
            position: absolute;
            bottom: 9px;
          }
        }
        .selectskuholder {
          position: absolute;
          bottom: 50px;
          width: 100%;
          .sbHolder {
            width: 100% !important;
          }
        }
      }
      .reviews {
        padding-left: 0px;
      }
      .badge {
        left: 0px;
      }
    }
    .mpp-wrapper {
      ul.breadcrumbs {
        margin-top: 15px;
        li {
          display: inline;
        }
      }
    }
  }
  section.clinique-spinner section {
    &#play-again-popup {
      .play-again-popup-container {
        h2.clinique-spinner-title {
          margin: 0px auto 10px;
        }
      }
    }
    &#game-win-box {
      .game-win-description {
        h2 {
          font-size: 30px;
        }
      }
      .pop-up-tcs {
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

.video-category-row-v1 {
  .item-video {
    .item-video-meta {
      display: none !important;
    }
  }
}

.checkout {
  .checkout-wrapper.empty {
    .cross_sell_container {
      display: block;
    }
  }
}

#header {
  .outer-wrap {
    .node-elc-nodeblock {
      .field-elc-nodeblock-content {
        .site-logo-responsive-v1 {
          .left_logo-link {
            float: left;
          }
          .right_logo-link {
            float: right;
          }
        }
      }
    }
  }
  .block-template-offer-promo-v1 {
    width: 36%;
  }
}

.gnav-cart-v1 {
  .cart-confirm-wrapper {
    .cart-overlay-products {
      .prod-info {
        .prod-details {
          .qty-price {
            .qty {
              float: left;
              margin-right: 35px;
            }
            .price {
              width: 58px;
              float: right;
            }
          }
        }
      }
    }
    .items {
      .items-in-cart,
      .item-in-cart {
        float: right;
        padding: 0 0 0 10px;
      }
    }
  }
}

.past-purchases {
  .accordion-content {
    .shipments-list {
      .products {
        .product {
          .quantity {
            font-weight: normal;
          }
          .product-info {
            .product-name a {
              color: #44a07e;
              display: block;
            }
          }
        }
      }
    }
  }
}

.blue-ocean-merch-window-v1.diagnostics-merch-window.has-image {
  height: 385px;
  margin: 18px auto 0 auto;
}

.foundation-finder-merch-window-v1.diagnostics-merch-window.has-image {
  height: 390px;
}

.blueocean {
  .diagnostic-results {
    ul.products li {
      position: relative;
      .product-info {
        margin-left: 0px !important;
        div,
        a {
          text-align: center;
          display: block;
        }
      }
      a.add-to-bag {
        display: block;
        margin-left: 0px !important;
        position: absolute;
        bottom: 0px;
        width: 90%;
      }
    }
  }
}

.checkout {
  #bottom-checkout-btn {
    .checkout-buttons {
      .button {
        font-size: 0.9em;
      }
    }
  }
  #checkout-sidebar {
    #offer-code-panel {
      #offer_code {
        .offer_submit {
          margin-top: 15px;
          height: 27px;
          line-height: 0px;
          font-size: 0.9em;
          width: 68px;
          &.alt-button {
            border: 1px solid #5dba98 !important;
          }
        }
      }
    }
  }
  form#checkout_shipping {
    .continue-checkout {
      .button {
        width: 67px;
        height: 25px;
        line-height: 0px;
        font-size: 0.9em;
      }
    }
  }
}

#contact-form-wrap.personal-info-form-container {
  .second-column-wrap {
    .button_container {
      input[type='submit'] {
        font-weight: normal !important;
      }
    }
  }
  /* Changes to get contact us page personal info layout as same as EL TW */
  .first-column-wrap {
    .email_address_container,
    .country_container,
    .address_type_container,
    .phone1_container,
    .registered_member_container {
      clear: left;
    }
    .address1_container,
    .address2_container {
      width: 93%;
      input {
        width: 100%;
      }
    }
    .country_container {
      width: 45%;
    }
    input[type='radio'] {
      width: 13px;
      height: 13px;
      line-height: normal;
      padding: 0;
      display: inline;
      vertical-align: middle;
      margin-right: 8px;
      border: 0px;
    }
  }
}

input.form-submit,
input.button[type='submit'] {
  border: none !important;
}

.mpp-product-group-v1 {
  .mpp-product-v1 {
    .product {
      min-height: 580px;
      .product-details {
        min-height: 580px;
        .btn-shopnow {
          display: block;
          margin: 0 0 0 15%;
          position: absolute;
          bottom: 20px;
        }
        .button-secondary {
          right: 15px;
        }
        .reviews {
          position: absolute;
          bottom: 42px;
        }
        .product-info {
          h3 {
            margin-bottom: 0px;
            line-height: 0.5;
          }
        }
        .spp_product_status {
          position: absolute;
          bottom: 20px;
          left: 47px;
        }
      }
    }
  }
}

.pcg-product-large-v1 {
  height: 540px;
  .mpp-box {
    height: 500px;
    .product-info {
      top: 0px;
      height: 180px;
    }
    .mpp-product-hover-view {
      display: block;
      border: none;
      .btn-quickview {
        display: none;
      }
      .badge {
        left: 0;
        top: 50px;
      }
    }
    .badge {
      left: 0;
      top: 50px;
    }
    .btn-shopnow {
      position: absolute;
      bottom: 20px;
    }
  }
}

#samples {
  .samples-page {
    .samples {
      #tray_container {
        .tray_samples {
          .number_box {
            min-width: 250px;
            .tray_sample {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.power_couple {
  .power_product {
    .product-info a {
      display: block;
    }
  }
}

.video-with-products-sidebar-v1 {
  .product {
    .product-info a {
      display: block;
    }
  }
}

.promo_product_row {
  .product {
    .product-title span {
      display: block;
    }
  }
}

#sticky-bar {
  height: 60px;
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_info {
        max-width: 35%;
        h4 {
          padding-top: 3px;
          height: auto;
          float: none;
        }
      }
      .sticky_prod_select {
        margin-top: 5px;
        padding-right: 175px;
        .dropdown {
          padding: 4px 30px 2px 10px;
          height: 46px;
          margin-top: 1px;
          .selected_sku {
            span {
              margin-right: 10px;
              display: inline-block;
              float: none;
            }
            .label {
              display: block;
            }
            &.sized {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  .tertiary-nav {
    li {
      height: 59px;
      padding-top: 12px;
      &.live-chat {
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 978px) {
  #header {
    .outer-wrap {
      .column.branding {
        width: 37%;
      }
    }
  }
  #footer {
    .footer-top-v1 {
      .tel {
        width: 21%;
        margin: 5px 0px 0px 7px;
      }
      .social-links {
        width: 14%;
        margin-top: 5px;
      }
      .signup-forms {
        width: 63%;
        form {
          label {
            display: inline;
            font-size: 10px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

.power-couples-formatter-v1 {
  .power-couples-formatter-rows {
    .promo_product_row {
      .product:hover {
        .btn-quickview {
          visibility: hidden;
        }
      }
      .badge {
        left: -15px;
      }
    }
  }
}

#ff {
  #ff-wrapper {
    #ff-content {
      #refine-prev {
        width: 70px;
      }
      #refine-next {
        width: 70px;
      }
      .dimensions {
        .accordion-content {
          .accordion-close {
            width: 70px;
          }
          .delayed-click {
            width: 76px;
          }
        }
      }
      #refinements {
        dl.dimension-8164 {
          ul.finish_help_text {
            width: 200px !important;
            padding: 22px 22px 4px 42px !important;
          }
          .finish_help_show {
            .finish_help_btm {
              width: 211px !important;
            }
          }
        }
      }
    }
  }
}

.power_couple_sticky_content {
  margin-left: 200px;
  padding-right: 50px;
}

.popup-offer-v1 {
  h2 {
    font-size: 26px !important;
    margin: 5px !important;
  }
  .terms_button_close {
    background: transparent url('/sites/clinique/themes/cl_base/img/icon-close.png') no-repeat scroll 0 0/15px 15px;
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 5px;
    text-align: right;
    top: 5px;
    width: 15px;
  }
  .popup-offer-close {
    right: 5px !important;
  }
  .subheading {
    p {
      line-height: 1.3;
    }
    .signup_terms_popup {
      text-decoration: underline;
    }
    .signup_popup_image {
      float: left;
    }
  }
  .terms_message {
    height: 442px;
    .terms_title {
      text-decoration: underline;
      text-align: center;
      list-style-type: none;
    }
    p {
      text-align: left;
      list-style-type: disc;
      display: list-item;
      margin: 10px;
      font-size: 22px;
    }
  }
  .signup-forms {
    #popover-newsletter-signup {
      float: left;
      width: 50%;
      .form-submit-wrap {
        position: relative;
        left: 90px;
        margin-bottom: 15px;
        .form-text {
          margin-bottom: 10px;
          width: 100%;
        }
        .form-submit {
          margin-left: 30px;
        }
      }
      .error {
        margin-left: 20px;
      }
      .popup-offer-msg {
        margin-left: 100px;
        width: 100%;
      }
    }
  }
  .signedup {
    padding: 50px !important;
    .subheading {
      p {
        padding: 10px;
      }
    }
  }
  .thankyou {
    padding: 50px !important;
    .subheading {
      p {
        padding: 20px;
      }
    }
  }
}

.write_review #BVSubmissionContainer {
  padding-bottom: 80px;
}

.section-ebc-landing-page {
  .ebc-grid-item__date {
    display: none;
  }
  .ebc-grid-item__expando-date {
    display: none;
  }
}

.blueocean {
  .diagnostic-results {
    ul.products {
      li {
        .spp_product_status {
          bottom: 0;
          margin-left: 65px !important;
          position: absolute;
          .temp_out_of_stock_msg {
            margin-left: -31px !important;
          }
        }
      }
    }
  }
}

.pc_spp_view .sticky_content .spp_product_status {
  clear: none;
}

#main.samples {
  #content {
    #sample-products {
      margin-bottom: 15px;
      .freebie {
        .cat_items {
          ul.sample-products {
            margin-bottom: 15px;
            li.sample-box {
              min-height: 260px;
              height: auto;
            }
            li.sample-box.sample_border {
              border: 1px solid #1f1f1f;
            }
            li.sample-box.deactivate {
              color: #cccccc;
              .product-img {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}

#main.samples {
  #content {
    #navigation {
      margin-top: 20px;
      .right.checkout_button {
        float: right;
      }
      a.no_thanks {
        float: right;
      }
      .checkout_button.disabled {
        background: #cccccc;
      }
    }
    .skip_continue {
      margin-top: 10px;
      a.no_thanks {
        float: right;
      }
    }
  }
  .gwp_samples {
    background: #ccf3ee;
    .select_sample {
      font-size: 30px;
    }
    .offer_text {
      font-size: 15px;
    }
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  background-image: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_DESKTOP_Chinese.jpg');
}

.module-spp-detail .col2 {
  .product-offer {
    margin-top: 5px;
    vertical-align: middle;
    text-align: center;
    background: #f5f5f5;
    padding: 20px 10px;
    .product-offer__header {
      padding-bottom: 5px;
      color: #46ab8c;
      font-size: 22px;
      text-align: center;
      font-weight: bold;
    }
    .product-offer__text {
      border-top: 1px solid #7b7b7b;
      padding-top: 10px;
      text-align: left;
      margin-left: 15px;
    }
  }
}

.appt-book-datepicker-calendar-container {
  .pika-single {
    margin-top: 16px;
  }
}

@media #{$medium-only} {
  .appt-book .appointment-select .appointments-container .confirm-container .total-time {
    padding-left: 22px;
  }
}

.appt-book {
  .appt-book-page-header {
    .appt-book-page-header-content {
      .inner {
        h2 {
          font-size: 54px;
          margin-bottom: 10px;
        }
      }
      .appt-book-page-header-content__title {
        width: 60%;
        h2 {
          font-size: 51px;
        }
      }
      .appt-book-page-header-content__subhead {
        margin-top: 15px;
        p {
          font-size: 15px;
        }
      }
    }
  }
  #appointment-book-sections {
    #booking-step1 {
      .artist-select-container {
        display: none !important;
      }
    }
    #booking-step3 {
      .registration__terms.error {
        color: #ef6ea8;
      }
    }
    #counters {
      .selectBox,
      .location-submit {
        width: 300px;
      }
    }
  }
}

.spp_page_wrap {
  .container {
    .product-full {
      &__media {
        vertical-align: top;
        display: inline-block;
        @media #{$medium-up} {
          width: 48%;
          display: inline-block;
          margin-bottom: 20px;
        }
        .product-full {
          &__image {
            display: inline-block;
            vertical-align: top;
            margin: 0 auto;
            position: relative;
            max-width: 402px;
            &-wrapper {
              display: inline-block;
              position: relative;
              vertical-align: top;
            }
            &--slide {
              width: 402px;
              height: 464px;
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: block;
                }
              }
              img {
                width: 402px;
                height: 464px;
              }
            }
            &__video {
              position: relative;
              &.slick-slide {
                img.mobile-hidden {
                  display: none;
                }
              }
              &-overlay {
                background-image: url('/media/images/icons/video_play.png');
                background-repeat: no-repeat;
                background-position: top right;
                padding: 20px;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
              }
              .poster-frame {
                width: 100%;
              }
            }
          }
          &__alt-images {
            display: inline-block;
            vertical-align: top;
            position: relative;
            &-slider {
              display: none;
              margin: 60px 20px 0 0;
              width: 50px;
              @media #{$medium-up} {
                display: none;
              }
              &.active {
                @media #{$medium-up} {
                  display: inline-block;
                }
              }
              .slick-slide {
                border: none;
              }
              .slick-arrow {
                height: 6px;
                width: 11px;
                left: 20px;
                position: relative;
                z-index: 100;
                padding: auto;
              }
              .slick-prev {
                top: -25px;
                background: url('/media/images/icons/spp_thumb_carousel_caret_up.png') no-repeat;
                &:before {
                  content: none;
                }
              }
              .slick-next {
                bottom: -25px;
                background: url('/media/images/icons/spp_thumb_carousel_caret_down.png') no-repeat;
                &:before {
                  content: none;
                }
              }
              &-button {
                height: 6px;
                width: 11px;
                margin: 0 auto;
                cursor: pointer;
              }
            }
            &-thumb {
              padding: 5px 0;
              cursor: pointer;
              border-bottom: 3px solid $color-white;
              &.active {
                border-color: $color-black;
              }
              img {
                width: 50px;
              }
            }
          }
          .badge {
            @include border-radius(50%);
            position: absolute;
            top: 50px;
            #{$ldirection}: 50px;
            display: table;
            width: 65px;
            height: 65px;
            color: $color-white;
            z-index: 1;
            padding: 0;
            line-height: 14px;
            font-family: $base-font-family;
            vertical-align: middle;
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            &--large {
              top: 0;
              left: 0;
            }
            &.badge_1,
            &.badge_2,
            &.badge_3,
            &.badge_4,
            &.badge_5,
            &.badge_17,
            &.badge_92,
            &.badge_94,
            &.badge_96,
            &.badge_97 {
              background: $color-cl-grey;
            }
            &.badge_96 {
              font-size: 12px;
            }
            &.badge_30 {
              background: $color-cl-grey;
            }
            html.ie7 & {
              text-align: center;
            }
            .badge-inner {
              display: table-cell;
              vertical-align: middle;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.sticky_prod_select {
  .giftset_components {
    .product-size {
      &__non-shaded {
        display: none;
      }
    }
  }
}

#clinique-cny {
  &.main-clinique-cny {
    font-family: $base-font-family;
  }
}
