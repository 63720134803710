/* Email signup waitlist Popup */

ul.error {
  margin-top: 5px;
}

.error_messages li {
  background-clip: padding-box;
  background: none;
  border-radius: 3px;
  color: #ef6ea8;
  font-size: 12px;
  line-height: 1.3em;
  margin: 0px;
  padding: 0px;
}

body.elc-user-state-anonymous {
  color: #1f1f1f;
}

.tiny-waitlist-overlay {
  width: 100%;
  p {
    color: #1f1f1f;
  }
  .field {
    margin-top: 12px;
    margin-bottom: 15px;
  }
  form .field {
    label {
      color: #1f1f1f;
      font-weight: normal;
      text-transform: capitalize;
      font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
      margin-right: 10px;
    }
    input {
      border: 1px solid #dbdcdd;
      height: 30px;
      width: 160px;
    }
  }
  .align-r input {
    background: none repeat scroll 0 0 #5db997;
    border: 0 none;
    color: #ffffff;
    display: inline-block;
    float: right;
    font-family: 'HelveticaNeueLTStd45Mideum', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    font-weight: bold;
    height: 30px;
    padding: 0 13px;
    text-transform: uppercase;
    border-radius: 0;
  }
  .gray {
    font-size: 12px;
  }
}

.waitlist_overlay_content {
  text-align: center;
}

.temp_out_of_stock_msg .email-me {
  display: none;
}
